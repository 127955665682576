import Bugsnag from "@bugsnag/js";
import store from "@/store";
import log from "@/lib/calendesk-js-library/tools/log";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export function successNotification(message) {
  store.commit("SET_SNACKBAR", {
    color: "success",
    text: trans(message),
    icon: "$info",
  });
}

export function warningNotification(
  message,
  notificationId,
  translationParams = {},
  redirect = null,
  redirectText = null,
) {
  store.commit("SET_SNACKBAR", {
    color: "orange",
    text: trans(message, translationParams),
    timeout: "-1",
    closable: true,
    notificationId: notificationId,
    icon: "$alert",
    redirect,
    redirectText,
  });
}

export function errorNotification(
  message,
  details,
  report = true,
  translationParams = {},
  redirect = null,
  redirectText = null,
) {
  if (!message) {
    message = "error_occurred";
  }
  store.commit("SET_SNACKBAR", {
    color: "red-light",
    text: trans(message, translationParams),
    timeout: 7000,
    closable: true,
    icon: "$alert",
    redirect,
    redirectText,
  });

  pushEvent("errorNotification", {
    message: message,
    details: details,
    report: report,
  });

  if (
    details &&
    details.response &&
    details.response.data &&
    details.response.data.code === "NOT_FOUND"
  ) {
    report = false;
  }

  reportError(message, details, report);
}

export function reportError(message, details, report = true) {
  // Handle axios error response
  if (details && details.response && details.response.data) {
    details = details.response.data;
  }

  details = sanitizeData(details);

  // Log locally
  log.error("errorNotification", [message, details]);

  if (report && process.env.NODE_ENV === "production") {
    // Safely stringify the details
    const safeStringify = (obj) => {
      try {
        return typeof obj === "object" ? JSON.stringify(obj) : String(obj);
      } catch (e) {
        return String(obj);
      }
    };

    // Create a safe error payload
    const errorPayload = [
      message,
      safeStringify(details),
      safeStringify(store.getters["setup/getTenant"]),
      safeStringify(store.getters["setup/getLocale"]),
    ];

    Bugsnag.notify(new Error(errorPayload.join(" | ")));
  }
}

function sanitizeData(data) {
  if (!data) return data;

  // Handle strings (like JSON strings)
  if (typeof data === "string") {
    let sanitized = data;

    // Mask JWT tokens
    sanitized = sanitized.replace(
      /(eyJ[a-zA-Z0-9_-]{5,}\.eyJ[a-zA-Z0-9_-]{5,})\.[a-zA-Z0-9_-]+/g,
      "$1.[REDACTED]",
    );

    // Mask Authorization headers
    sanitized = sanitized.replace(
      /(Authorization['"]?\s*:\s*['"]?Bearer\s+)[^'")\s]+/g,
      "$1[REDACTED]",
    );

    return sanitized;
  }

  // Handle objects recursively
  if (typeof data === "object") {
    const result = Array.isArray(data) ? [] : {};

    for (const key in data) {
      // Skip if property doesn't exist or is not enumerable
      if (!Object.prototype.hasOwnProperty.call(data, key)) continue;

      // Directly mask sensitive keys
      if (/token|auth|password|secret/i.test(key)) {
        result[key] = "[REDACTED]";
      }
      // Special handling for headers objects
      else if (key === "headers" && typeof data[key] === "object") {
        result[key] = { ...data[key] };
        if (result[key].Authorization) {
          result[key].Authorization = result[key].Authorization.replace(
            /(Bearer\s+)[^'")\s]+/g,
            "$1[REDACTED]",
          );
        }
      }
      // Recursively sanitize nested objects
      else {
        result[key] = sanitizeData(data[key]);
      }
    }

    return result;
  }

  return data;
}
